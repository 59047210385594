
:root {

  --wanda-spacing: 4px;
  --wanda-border-radius: 4px;
  --wanda-border-radius-sm: calc(var(--wanda-borderRadius) * 2);
  --wanda-border-radius-md: calc(var(--wanda-borderRadius) * 3);
  --wanda-border-radius-pill: 9999px;

  --wanda-shadow-0: none;
  --wanda-shadow-1: 0 1px 2px rgb(0 0 0 / .3), 0 1px 3px 1px rgb(0 0 0 / .15)`;
  --wanda-shadow-2: 0 1px 2px rgb(0 0 0 / .3), 0 2px 6px 2px rgb(0 0 0 / .15)`;
  --wanda-shadow-3: 0 1px 3px rgb(0 0 0 / .3), 0 4px 8px 3px rgb(0 0 0 / .15)`;
  --wanda-shadow-4: 0 2px 3px rgb(0 0 0 / .3), 0 6px 10px 4px rgb(0 0 0 / .15)`;
  --wanda-shadow-5: 0 4px 4px rgb(0 0 0 / .3), 0 8px 12px 6px rgb(0 0 0 / .15)`;

  /* BASE */
  --wanda-base01: #FFFFFF;
  --wanda-base02: #F6F8FB;
  --wanda-base03: #DDE2F0;
  --wanda-base04: #7587B8;
  --wanda-base05: #475E9F;
  --wanda-base06: #3A4971;
  --wanda-base07: #2C3854;
  --wanda-base08: #242F4A;
  --wanda-base09: #202A42;

  /* IDENTITY */
  --wanda-identityBlue01: #EFF5FF;
  --wanda-identityBlue02: #8DBCFF;
  --wanda-identityBlue03: #0071DB;
  --wanda-identityYellow01: #FFF4D7;
  --wanda-identityYellow02: #FFBE12;
  --wanda-identityYellow03: #936B00;

  /* STATUS */
  --wanda-statusGreen01: #E4FBD7;
  --wanda-statusGreen02: #5ED218;
  --wanda-statusGreen03: #3A810F;
  --wanda-statusOrange01: #FEF3E4;
  --wanda-statusOrange02: #FCA642;
  --wanda-statusOrange03: #AA5C00;
  --wanda-statusRed01: #FFF1F1;
  --wanda-statusRed02: #FF9F9B;
  --wanda-statusRed03: #CA4238;

  /* ACCENT */
  --wanda-accentCyan01: #DBFAFD;
  --wanda-accentCyan02: #0BCCE2;
  --wanda-accentCyan03: #007E8D;
  --wanda-accentPink01: #FFEFFE;
  --wanda-accentPink02: #FF91FD;
  --wanda-accentPink03: #CB03C8;
  --wanda-accentPurple01: #F4F2FF;
  --wanda-accentPurple02: #BCB0FF;
  --wanda-accentPurple03: #7058F3;

  /* TYPOGRAPHY */
  --wanda-font-family: "Mona Sans";

  /* H1 */
  --wanda-h1-font-family: var(--wanda-font-family);
  --wanda-h1-font-size: 32px;
  --wanda-h1-font-style: normal;
  --wanda-h1-font-weight: 700;
  --wanda-h1-line-height: 40px; /* 125% */
  --wanda-h1-letter-spacing: 0.25px;

  /* H2 */
  --wanda-h2-font-family: var(--wanda-font-family);
  --wanda-h2-font-size: 28px;
  --wanda-h2-font-style: normal;
  --wanda-h2-font-weight: 700;
  --wanda-h2-line-height: 36px; /* 128.571% */
  --wanda-h2-letter-spacing: 0.25px;

  /* H3 */
  --wanda-h3-font-family: var(--wanda-font-family);
  --wanda-h3-font-size: 24px;
  --wanda-h3-font-style: normal;
  --wanda-h3-font-weight: 600;
  --wanda-h3-line-height: 32px; /* 133.333% */
  --wanda-h3-letter-spacing: 0.25px;

  /* H4 */
  --wanda-h4-font-family: var(--wanda-font-family);
  --wanda-h4-font-size: 22px;
  --wanda-h4-font-style: normal;
  --wanda-h4-font-weight: 600;
  --wanda-h4-line-height: 28px; /* 127.273% */
  --wanda-h4-letter-spacing: 0.25px;

  /* H5 */
  --wanda-h5-font-family: var(--wanda-font-family);
  --wanda-h5-font-size: 18px;
  --wanda-h5-font-style: normal;
  --wanda-h5-font-weight: 600;
  --wanda-h5-line-height: 24px; /* 133.333% */
  --wanda-h5-letter-spacing: 0.25px;

  /* H6 */
  --wanda-h6-font-family: var(--wanda-font-family);
  --wanda-h6-font-size: 16px;
  --wanda-h6-font-style: normal;
  --wanda-h6-font-weight: 600;
  --wanda-h6-line-height: 20px; /* 125% */
  --wanda-h6-letter-spacing: 0.25px;

  /* Subtitle 1 (Regular) */
  --wanda-subtitle1-font-family: var(--wanda-font-family);;
  --wanda-subtitle1-font-size: 16px;
  --wanda-subtitle1-font-style: normal;
  --wanda-subtitle1-font-weight: 400;
  --wanda-subtitle1-line-height: 24px; /* 150% */
  --wanda-subtitle1-letter-spacing: 0.25px;

  /* Subtitle 2 (Light) */
  --wanda-subtitle2-font-family: var(--wanda-font-family);;
  --wanda-subtitle2-font-size: 14px;
  --wanda-subtitle2-font-style: normal;
  --wanda-subtitle2-font-weight: 300;
  --wanda-subtitle2-line-height: 20px; /* 142.857% */
  --wanda-subtitle2-letter-spacing: 0.25px;

  /* Body 1 (Regular) */
  --wanda-body1-font-family: var(--wanda-font-family);;
  --wanda-body1-font-size: 14px;
  --wanda-body1-font-style: normal;
  --wanda-body1-font-weight: 400;
  --wanda-body1-line-height: 20px; /* 142.857% */
  --wanda-body1-letter-spacing: 0.25px;

  /* Body 2 (Light) */
  --wanda-body2-font-family: var(--wanda-font-family);;
  --wanda-body2-font-size: 14px;
  --wanda-body2-font-style: normal;
  --wanda-body2-font-weight: 300;
  --wanda-body2-line-height: 20px; /* 142.857% */
  --wanda-body2-letter-spacing: 0.25px;

  /* Body 0 (Bold) */
  --wanda-body0-font-family: var(--wanda-font-family);;
  --wanda-body0-font-size: 14px;
  --wanda-body0-font-style: normal;
  --wanda-body0-font-weight: 700;
  --wanda-body0-line-height: 20px; /* 142.857% */
  --wanda-body0-letter-spacing: 0.25px;

  /* Body Link */
  --wanda-body-link-font-family: var(--wanda-font-family);
  --wanda-body-link-font-size: 14px;
  --wanda-body-link-font-style: normal;
  --wanda-body-link-font-weight: 400;
  --wanda-body-link-line-height: 20px; /* 142.857% */
  --wanda-body-link-letter-spacing: 0.25px;
  --wanda-body-link-text-decoration-line: underline;
  --wanda-body-link-text-decoration-style: solid;
  --wanda-body-link-text-decoration-skip-ink: none;

  /* Button */
  --wanda-button-font-family: var(--wanda-font-family);
  --wanda-button-font-size: 14px;
  --wanda-button-font-style: normal;
  --wanda-button-font-weight: 500;
  --wanda-button-line-height: 20px; /* 142.857% */
  --wanda-button-letter-spacing: 0.5px;

  .wanda-angular {
    font-family: var(--wanda-body1-font-family);
    font-size: var(--wanda-body1-font-size);
    font-style: var(--wanda-body1-font-style);
    font-weight: var(--wanda-body1-font-weight);
    line-height: var(--wanda-body1-line-height);
    letter-spacing: var(--wanda-body1-letter-spacing);

    h1 {
      font-family: var(--wanda-h1-font-family);
      font-size: var(--wanda-h1-font-size);
      font-style: var(--wanda-h1-font-style);
      font-weight: var(--wanda-h1-font-weight);
      line-height: var(--wanda-h1-line-height);
      letter-spacing: var(--wanda-h1-letter-spacing);
    }

    h2 {
      font-family: var(--wanda-h2-font-family);
      font-size: var(--wanda-h2-font-size);
      font-style: var(--wanda-h2-font-style);
      font-weight: var(--wanda-h2-font-weight);
      line-height: var(--wanda-h2-line-height);
      letter-spacing: var(--wanda-h2-letter-spacing);
    }

    h3 {
      font-family: var(--wanda-h3-font-family);
      font-size: var(--wanda-h3-font-size);
      font-style: var(--wanda-h3-font-style);
      font-weight: var(--wanda-h3-font-weight);
      line-height: var(--wanda-h3-line-height);
      letter-spacing: var(--wanda-h3-letter-spacing);
    }

    h4 {
      font-family: var(--wanda-h4-font-family);
      font-size: var(--wanda-h4-font-size);
      font-style: var(--wanda-h4-font-style);
      font-weight: var(--wanda-h4-font-weight);
      line-height: var(--wanda-h4-line-height);
      letter-spacing: var(--wanda-h4-letter-spacing);
    }

    h5 {
      font-family: var(--wanda-h5-font-family);
      font-size: var(--wanda-h5-font-size);
      font-style: var(--wanda-h5-font-style);
      font-weight: var(--wanda-h5-font-weight);
      line-height: var(--wanda-h5-line-height);
      letter-spacing: var(--wanda-h5-letter-spacing);
    }

    h6 {
      font-family: var(--wanda-h6-font-family);
      font-size: var(--wanda-h6-font-size);
      font-style: var(--wanda-h6-font-style);
      font-weight: var(--wanda-h6-font-weight);
      line-height: var(--wanda-h6-line-height);
      letter-spacing: var(--wanda-h6-letter-spacing);
    }

    .subtitle1 {
      font-family: var(--wanda-subtitle1-font-family);
      font-size: var(--wanda-subtitle1-font-size);
      font-style: var(--wanda-subtitle1-font-style);
      font-weight: var(--wanda-subtitle1-font-weight);
      line-height: var(--wanda-subtitle1-line-height);
      letter-spacing: var(--wanda-subtitle1-letter-spacing);
    }

    .subtitle2 {
      font-family: var(--wanda-subtitle2-font-family);
      font-size: var(--wanda-subtitle2-font-size);
      font-style: var(--wanda-subtitle2-font-style);
      font-weight: var(--wanda-subtitle2-font-weight);
      line-height: var(--wanda-subtitle2-line-height);
      letter-spacing: var(--wanda-subtitle2-letter-spacing);
    }

    .body2 {
      font-family: var(--wanda-body2-font-family);
      font-size: var(--wanda-body2-font-size);
      font-style: var(--wanda-body2-font-style);
      font-weight: var(--wanda-body2-font-weight);
      line-height: var(--wanda-body2-line-height);
      letter-spacing: var(--wanda-body2-letter-spacing);
    }

    .body0 {
      font-family: var(--wanda-body0-font-family);
      font-size: var(--wanda-body0-font-size);
      font-style: var(--wanda-body0-font-style);
      font-weight: var(--wanda-body0-font-weight);
      line-height: var(--wanda-body0-line-height);
      letter-spacing: var(--wanda-body0-letter-spacing);
    }

    a {
      font-family: var(--wanda-body-link-font-family);
      font-size: var(--wanda-body-link-font-size);
      font-style: var(--wanda-body-link-font-style);
      font-weight: var(--wanda-body-link-font-weight);
      line-height: var(--wanda-body-link-line-height);
      letter-spacing: var(--wanda-body-link-letter-spacing);
      text-decoration-line: var(--wanda-body-link-text-decoration-line);
      text-decoration-style: var(--wanda-body-link-text-decoration-style);
      text-decoration-skip-ink: var(--wanda-body-link-text-decoration-skip-ink);
    }

    button {
      font-family: var(--wanda-button-font-family);
      font-size: var(--wanda-button-font-size);
      font-style: var(--wanda-button-font-style);
      font-weight: var(--wanda-button-font-weight);
      line-height: var(--wanda-button-line-height);
      letter-spacing: var(--wanda-button-letter-spacing);
    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --wanda-container-fill-nav: var(--wanda-base07);
    --wanda-container-fill-body: var(--wanda-base09);
    --wanda-container-fill-card1: var(--wanda-base08);
    --wanda-container-fill-card2: var(--wanda-base07);

    --wanda-container-border-default: var(--wanda-base06);
    --wanda-container-border-active: var(--wanda-identityBlue02);
    --wanda-container-border-input: var(--wanda-base04);

    --wanda-text-title: var(--wanda-base01);
    --wanda-text-body: var(--wanda-base03);
    --wanda-text-soft: var(--wanda-identityBlue02);

    --wanda-text-status-inProgress: var(--wanda-accentPink02);
    --wanda-text-status-success: var(--wanda-accentCyan02);
    --wanda-text-status-warning: var(--wanda-statusOrange02);
    --wanda-text-status-error: var(--wanda-statusRed02);

    --wanda-clickable-default: var(--wanda-identityYellow02);
    --wanda-clickable-hover: var(--wanda-identityYellow01);

    --wanda-clickable-nav-item-default: var(--wanda-identityBlue02);
    --wanda-clickable-nav-item-active: var(--wanda-base01);

    --wanda-clickable-button-text: var(--wanda-base09);
    --wanda-clickable-button-default: var(--wanda-identityYellow02);
    --wanda-clickable-button-hover: var(--wanda-identityYellow01);

    --wanda-clickable-button-danger-default: var(--wanda-statusRed02);
    --wanda-clickable-button-danger-hover: var(--wanda-statusRed01);

    --wanda-badge-text: var(--wanda-base01);

    --wanda-badge-default-border: var(--wanda-base06);
    --wanda-badge-default-fill: var(--wanda-base07);

    --wanda-badge-primary-border: var(--wanda-identityYellow02);
    --wanda-badge-primary-fill: var(--wanda-identityYellow03);

    --wanda-badge-inProgress-border: var(--wanda-accentPink02);
    --wanda-badge-inProgress-fill: var(--wanda-accentPink03);

    --wanda-badge-success-border: var(--wanda-accentCyan02);
    --wanda-badge-success-fill: var(--wanda-accentCyan03);

    --wanda-badge-warning-border: var(--wanda-statusOrange02);
    --wanda-badge-warning-fill: var(--wanda-statusOrange03);

    --wanda-badge-error-border: var(--wanda-statusRed02);
    --wanda-badge-error-fill: var(--wanda-statusRed03);

    --wanda-badge-accent1-border: var(--wanda-accentCyan02);
    --wanda-badge-accent1-fill: var(--wanda-accentCyan03);

    --wanda-badge-accent2-border: var(--wanda-accentPink02);
    --wanda-badge-accent2-fill: var(--wanda-accentPink03);

    --wanda-badge-accent3-border: var(--wanda-accentPurple02);
    --wanda-badge-accent3-fill: var(--wanda-accentPurple03);

    --wanda-badge-accent4-border: var(--wanda-identityBlue02);
    --wanda-badge-accent4-fill: var(--wanda-identityBlue03);

    --wanda-misc-progressbar-default: var(--wanda-accentCyan02);
    --wanda-misc-progressbar-bg: var(--wanda-base06);

    --wanda-skeleton-default: var(--wanda-base06);

    --wanda-graph-data-a1: var(--wanda-accentCyan02);
    --wanda-graph-data-a2: var(--wanda-accentCyan03);
    --wanda-graph-data-b1: var(--wanda-accentPink02);
    --wanda-graph-data-b2: var(--wanda-accentPink03);
    --wanda-graph-data-c1: var(--wanda-accentPurple02);
    --wanda-graph-data-c2: var(--wanda-accentPurple03);
    --wanda-graph-data-d1: var(--wanda-identityBlue02);
    --wanda-graph-data-d2: var(--wanda-identityBlue03);
    --wanda-graph-data-e1: var(--wanda-statusOrange02);
    --wanda-graph-data-e2: var(--wanda-statusOrange03);
    --wanda-graph-data-f1: var(--wanda-statusRed02);
    --wanda-graph-data-f2: var(--wanda-statusRed03);
    --wanda-graph-data-g1: var(--wanda-base05);
    --wanda-graph-data-g2: var(--wanda-base06);

    --wanda-graph-grid-lineStroke: var(--wanda-base06);
    --wanda-graph-grid-borderStroke: var(--wanda-base04);

    --wanda-graph-text-axisTitle: var(--wanda-base03);
    --wanda-graph-text-tickLabel: var(--wanda-base03);
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --wanda-container-fill-nav: var(--wanda-base01);
    --wanda-container-fill-body: var(--wanda-base02);
    --wanda-container-fill-card1: var(--wanda-base01);
    --wanda-container-fill-card2: var(--wanda-base02);

    --wanda-container-border-default: var(--wanda-base03);
    --wanda-container-border-active: var(--wanda-base05);
    --wanda-container-border-input: var(--wanda-base04);

    --wanda-text-title: var(--wanda-base09);
    --wanda-text-body: var(--wanda-base06);
    --wanda-text-soft: var(--wanda-base05);

    --wanda-text-status-inProgress: var(--wanda-accentPink03);
    --wanda-text-status-success: var(--wanda-accentCyan03);
    --wanda-text-status-warning: var(--wanda-statusOrange03);
    --wanda-text-status-error: var(--wanda-statusRed03);

    --wanda-clickable-default: var(--wanda-identityBlue03);
    --wanda-clickable-hover: var(--wanda-base08);

    --wanda-clickable-nav-item-default: var(--wanda-base05);
    --wanda-clickable-nav-item-active: var(--wanda-base09);

    --wanda-clickable-button-text: var(--wanda-base01);
    --wanda-clickable-button-default: var(--wanda-identityBlue03);
    --wanda-clickable-button-hover: var(--wanda-base08);

    --wanda-clickable-button-danger-default: var(--wanda-statusRed03);
    --wanda-clickable-button-danger-hover: var(--wanda-base08);

    --wanda-badge-text: var(--wanda-base07);

    --wanda-badge-default-border: var(--wanda-base03);
    --wanda-badge-default-fill: var(--wanda-base02);

    --wanda-badge-primary-border: var(--wanda-identityYellow02);
    --wanda-badge-primary-fill: var(--wanda-identityYellow01);

    --wanda-badge-inProgress-border: var(--wanda-accentPink02);
    --wanda-badge-inProgress-fill: var(--wanda-accentPink01);

    --wanda-badge-success-border: var(--wanda-accentCyan02);
    --wanda-badge-success-fill: var(--wanda-accentCyan01);

    --wanda-badge-warning-border: var(--wanda-statusOrange02);
    --wanda-badge-warning-fill: var(--wanda-statusOrange01);

    --wanda-badge-error-border: var(--wanda-statusRed02);
    --wanda-badge-error-fill: var(--wanda-statusRed01);

    --wanda-badge-accent1-border: var(--wanda-accentCyan02);
    --wanda-badge-accent1-fill: var(--wanda-accentCyan01);

    --wanda-badge-accent2-border: var(--wanda-accentPink02);
    --wanda-badge-accent2-fill: var(--wanda-accentPink01);

    --wanda-badge-accent3-border: var(--wanda-accentPurple02);
    --wanda-badge-accent3-fill: var(--wanda-accentPurple01);

    --wanda-badge-accent4-border: var(--wanda-identityBlue02);
    --wanda-badge-accent4-fill: var(--wanda-identityBlue01);

    --wanda-misc-progressbar-default: var(--wanda-accentCyan02);
    --wanda-misc-progressbar-bg: var(--wanda-base03);

    --wanda-skeleton-default: var(--wanda-base03);

    --wanda-graph-data-a1: var(--wanda-accentCyan03);
    --wanda-graph-data-a2: var(--wanda-accentCyan02);
    --wanda-graph-data-b1: var(--wanda-accentPink03);
    --wanda-graph-data-b2: var(--wanda-accentPink02);
    --wanda-graph-data-c1: var(--wanda-accentPurple03);
    --wanda-graph-data-c2: var(--wanda-accentPurple02);
    --wanda-graph-data-d1: var(--wanda-identityBlue03);
    --wanda-graph-data-d2: var(--wanda-identityBlue02);
    --wanda-graph-data-e1: var(--wanda-statusOrange03);
    --wanda-graph-data-e2: var(--wanda-statusOrange02);
    --wanda-graph-data-f1: var(--wanda-statusRed03);
    --wanda-graph-data-f2: var(--wanda-statusRed02);
    --wanda-graph-data-g1: var(--wanda-base04);
    --wanda-graph-data-g2: var(--wanda-base03);

    --wanda-graph-grid-lineStroke: var(--wanda-base03);
    --wanda-graph-grid-borderStroke: var(--wanda-base04);

    --wanda-graph-text-axisTitle: var(--wanda-base06);
    --wanda-graph-text-tickLabel: var(--wanda-base06);
  }
}





